import { useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";

export default function CookieConsent() {
  const isVisible = useSignal(true);

  // Client-side only code
  useEffect(() => {
    // Check if we're in the browser
    if (typeof window !== 'undefined') {
      const hasConsent = window.localStorage.getItem("cookieConsent");
      if (hasConsent === "accepted") {
        isVisible.value = false;
      }

      // Debug on client side
      console.log("CookieConsent mounted on client");
    }
  }, []);

  const acceptConsent = (e: MouseEvent) => {
    e.preventDefault();
    // Check if we're in the browser
    if (typeof window !== 'undefined') {
      console.log("Accept button clicked on client");
      try {
        window.localStorage.setItem("cookieConsent", "accepted");
        isVisible.value = false;
        console.log("Consent accepted and saved on client");
      } catch (err) {
        console.error("Error saving consent on client:", err);
      }
    }
  };

  if (!isVisible.value) {
    return null;
  }

  return (
    <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-blue-600 shadow-lg sm:p-3">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <p class="ml-3 font-medium text-white truncate">
                <span class="md:hidden">We use cookies.</span>
                <span class="hidden md:inline">
                  We use cookies to enhance your browsing experience and analyze our traffic.
                </span>
              </p>
            </div>
            <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <button
                onClick={acceptConsent}
                class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-blue-50"
              >
                Accept
              </button>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <a
                href="/privacy"
                class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white hover:text-blue-100"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
